// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'ace-builds/src-noconflict/ace'
// import 'ace-builds/src-noconflict/mode-html'
// import 'ace-builds/src-noconflict/mode-json'
// import 'ace-builds/src-noconflict/mode-markdown'
// import 'ace-builds/src-noconflict/theme-solarized_light'
import 'ace-builds/webpack-resolver' // this is auto-loading any theme or mode, but is very slow, so we explicitly load modes and themes we need

import 'arrive'
import 'bootstrap'
import 'controllers' // stimulus controllers, TODO: only cherry-pick the used ones

import '../website_custom'

// console.log('Hello Website from Webpacker')
