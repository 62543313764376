// import { Controller } from "stimulus"
import Conductor from 'stimulus-conductor'
import $ from 'jquery';

export default class extends Conductor {

  static targets = [ "klass", "instance" ]
  static musicianId = 'select2'

  update() {
    // called whenever a child select2 controller connects
    this.klassController() && this.instanceController() && this.setupCallback()
  }

  setupCallback() {
    $(this.klassController().selectTarget).on('change', (event) => {
      const newKlass = event.target.value
      const instanceSelect = this.instanceController().selectTarget
      const path = instanceSelect.dataset.source
      instanceSelect.dataset.source = path.split('?')[0] + '?klass=' + newKlass
      this.instanceController().select2refresh()
    })
  }

  klassController() {
    return this.select2Controllers.find((controller) => controller.selectTarget == this.klassTarget )
  }

  instanceController() {
    return this.select2Controllers.find((controller) => controller.selectTarget == this.instanceTarget )
  }

}
