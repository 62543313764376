import Conductor from 'stimulus-conductor'

export default class extends Conductor {

  // static conductorId = 'flatpickr-conductor'

  connect() {
    super.connect()
    this.element[this.identifier] = this // store controller into element for master/slave interactions
    // console.log(this.flatkidsController)
    // this.flatkidsController && this.flatkidsController.update()
  }

  /**
   * Propagate update information to parent controller
   */
  updated(){
    this.flatkidsController && this.flatkidsController.childDateChanged(this)
  }
}
