// source: https://github.com/pascallaliberte/stimulus-turbolinks-select2/blob/master/_assets/controllers/multi-select_controller.js
// using https://github.com/adrienpoly/stimulus-conductor to keep optional relation to parent controller (for chained selects etc.)

// import { Controller } from "stimulus"
import Conductor from 'stimulus-conductor'
import $ from 'jquery'

import Select2 from 'select2'
import 'select2/dist/js/i18n/cs'

export default class extends Conductor {

  static targets = [ "select" ]

  connect() {
    if (this.data.has('parentController')) {
      this.constructor.conductorId = this.data.get('parentController')
      super.connect()
    }
    this.select2mount()
    document.addEventListener("turbolinks:before-cache", () => {
      this.select2unmount()
    }, { once: true })
  }

  select2mount() {
    const opts = {
      minimumResultsForSearch: 8,
      placeholder: this.selectTarget.dataset.placeholder || '...',
      allowClear: true,
      theme: 'bootstrap4',
      width: '100%'
    }
    if (this.selectTarget.dataset.source) {
      opts['ajax'] = {
        url: this.selectTarget.dataset.source,
        dataType: 'json',
        data: function(term, page){ return { query: term } },
        processResults: function(data, page){ return { results: data } }
      }
    }
    $(this.selectTarget).select2(opts);
    // optionally register us for describably polymorph handling
    this.describableController && this.describableController.update()
  }

  select2unmount() {
    this.saveState()
    if ($(this.selectTarget).data('select2')) {
      $(this.selectTarget).select2('destroy')
    }
  }

  select2refresh() {
    // used mainly to change data-source for AJAX options retrieval
    $(this.selectTarget).val(null).select2('destroy')
    this.select2mount()
  }

  saveState() {
    // handle both - single value or array, depending on whether "multiple" flag was set on SELECT
    let values = [ $(this.selectTarget).val() ].flat()

    // make sure the HTML itself has those elements selected, since the HTML is what is saved in the turbolinks snapshot
    values.forEach((val) => {
      $(this.selectTarget).find(`option[value="${val}"]`).attr('selected', 'selected')
    })
  }

}
