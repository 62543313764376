import { Controller } from 'stimulus'
import 'datatables.net-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.html5'
import 'jszip'

// use locale-aware sorting

$.extend($.fn.dataTableExt.oSort, {
  "local-string-asc": function (s1, s2) {
    return s1.localeCompare(s2, document.documentElement.getAttribute('lang'));
  },

  "local-string-desc": function (s1, s2) {
    return s2.localeCompare(s1, document.documentElement.getAttribute('lang'));
  }
});

// tweak button appearance

$.extend(true, $.fn.dataTable.Buttons.defaults, {
  dom: {
    button: { className: 'btn btn-sm btn-outline-gold'}
  }
})

// launch DataTables

export default class extends Controller {

  static targets = ['source']

  connect() {
    this.dt = $(this.sourceTarget).DataTable({
      buttons: [ 'csvHtml5', 'copy' ],
      columnDefs: [ { targets: '_all', type: 'local-string' } ],
      // default value on: https://datatables.net/reference/option/dom
      dom: "<'row justify-content-between'<'col-auto'B><'col-auto'l><'col-auto align-self-center'f>><'row'<'col-sm-12'tr>><'row mb-1'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      info: false,
      language: cs,
      paging: false,
    })
    document.addEventListener("turbolinks:before-cache", () => { this.dataTablesUnmount() }, { once: true })
  }

  dataTablesUnmount() { this.dt.destroy() }

}

// i18n: https://datatables.net/plug-ins/i18n/Czech.html

let cs = {
  "emptyTable": "Tabulka neobsahuje žádná data",
  "info": "Zobrazuji _START_ až _END_ z celkem _TOTAL_ záznamů",
  "infoEmpty": "Zobrazuji 0 až 0 z 0 záznamů",
  "infoFiltered": "(filtrováno z celkem _MAX_ záznamů)",
  "infoThousands": " ",
  "lengthMenu": "Zobraz záznamů _MENU_",
  "loadingRecords": "Načítám...",
  "processing": "Provádím...",
  "search": "Hledat:",
  "zeroRecords": "Žádné záznamy nebyly nalezeny",
  "paginate": {
    "first": "První",
    "last": "Poslední",
    "next": "Další",
    "previous": "Předchozí"
  },
  "aria": {
    "sortAscending": ": aktivujte pro řazení sloupce vzestupně",
    "sortDescending": ": aktivujte pro řazení sloupce sestupně"
  },
  "buttons": {
    "colvis": "Zobrazení sloupců",
    "colvisRestore": "Původní nastavení"
  },
  "searchBuilder": {
    "add": "Přidat podmínku",
    "clearAll": "Smazat vše",
    "condition": "Podmínka",
    "conditions": {
      "date": {
        "after": "po",
        "before": "před",
        "between": "mezi",
        "empty": "prázdné",
        "equals": "rovno",
        "not": "není",
        "notBetween": "není mezi",
        "notEmpty": "není prázdné"
      },
      "moment": {
        "after": "po",
        "before": "před",
        "between": "mezi",
        "empty": "prázdné",
        "equals": "rovno",
        "not": "není",
        "notBetween": "není mezi",
        "notEmpty": "není prázdné"
      },
      "number": {
        "between": "mezi",
        "empty": "prázdné",
        "equals": "rovno",
        "gt": "větší",
        "gte": "rovno a větší",
        "lt": "menší",
        "lte": "rovno a menší",
        "not": "není",
        "notBetween": "není mezi",
        "notEmpty": "není prázdné"
      },
      "string": {
        "contains": "obsahuje",
        "empty": "prázdné",
        "endsWith": "končí na",
        "equals": "rovno",
        "not": "není",
        "notEmpty": "není prázdné",
        "startsWith": "začíná na"
      },
      "array": {
        "equals": "rovno",
        "empty": "prázdné",
        "contains": "obsahuje",
        "not": "není",
        "notEmpty": "není prázdné",
        "without": "neobsahuje"
      }
    },
    "data": "Sloupec",
    "logicAnd": "A",
    "logicOr": "NEBO",
    "title": [
      "Rozšířený filtr"
    ],
    "value": "Hodnota"
  },
  "select": {
    "1": "Vybrán %d záznam",
    "2": "Vybrány %d záznamy",
    "_": "Vybráno %d záznamů"
  }
}
