// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

// controllers from yarn packages - imports
import Clipboard from "stimulus-clipboard"
import ContentLoader from "stimulus-content-loader"
import NestedForm from "stimulus-rails-nested-form"

const application = Application.start()

// controllers from yarn packages - registration
application.register("clipboard", Clipboard)
application.register("content-loader", ContentLoader)
application.register("nested-form", NestedForm)

const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
