import Flatpickr from 'stimulus-flatpickr'

import { Czech } from 'flatpickr/dist/l10n/cs.js'
import { English } from 'flatpickr/dist/l10n/default.js'

export default class extends Flatpickr {

  locales = {
    cs: Czech,
    en: English
  }

  connect() {
    this.element[this.identifier] = this // store controller into element for master/slave interactions
    this.config = {
      locale: this.locale,
      altInput: true,
      altFormat: this.altFormat
    }
    super.connect()
  }

  get locale() {
    if (this.locales && this.data.has('locale')) {
      return this.locales[this.data.get('locale')];
    } else {
      return '';
    }
  }

  get altFormat() {
    if (this.data.has('enableTime')) {
      return 'j. F Y H:i'
    } else {
      return 'j. F Y'
    }
  }

  /**
   * Register change on the flatpickr element
   * 
   * If the flatpickr has flatkid attached call the parent
   * of flatkid (which is flatkids) and propagate the update
   * information.
   * 
   * This is needed for syncing to: and from: flatpickr instances
   */
  change(){
    this.element.flatkid && this.element.flatkid.updated()
  }

}
