import Reveal from 'stimulus-reveal-controller'

export default class extends Reveal {

  static targets = ['item', 'shower', 'hider']

  show() {
    super.show()
    this.showerTargets.forEach(shower => shower.classList.add(this.class))
    this.hiderTargets.forEach(hider => hider.classList.remove(this.class))
  }

  hide() {
    super.hide()
    this.showerTargets.forEach(shower => shower.classList.remove(this.class))
    this.hiderTargets.forEach(hider => hider.classList.add(this.class))
  }

  toggle(event) {
    // line below allows using links as togglers
    if (event.target.tagName !== "A"){
      super.toggle()
    }
  }

}
