import { Controller } from "stimulus"

import $ from 'jquery'
import Select2 from 'select2'
import 'select2/dist/js/i18n/cs'
import hotkeys from 'hotkeys-js'

export default class extends Controller {

  static targets = []

  connect() {
    this.select2mount()
    this.hotkeyMount()
    document.addEventListener("turbolinks:before-cache", () => {
      this.select2unmount()
    }, { once: true })
  }

  disconnect() {
    this.hotkeyUnMount()
    this.select2unmount()
  }

  select2mount() {
    let controller = this
    let input = $(this.element)
    input.select2({
      language: 'cs',
      width: 'auto',
      allowClear: true,
      minimumInputLength: 2,
      ajax: {
        url: input.data('source'),
        dataType: 'json',
        data: function (term, page) { return { query: term } },
        processResults: function (data, page) { return { results: data } }
      },
      templateResult: this.smart_search_template,
      templateSelection: function (item) { return (item.id ? item.name : item.text); }
    })
    input.on('select2:select', function (e) {
      input.empty()
      Turbolinks.visit(e.params.data.detail_url);
    })
    // add custom class on dropdown container so we can exclude this one from z-index patch in CSS
    // input.data('select2').$container.addClass('smart_search_container');
  }

  select2unmount() {
    if ($(this.element).data('select2')) {
      $(this.element).select2('destroy')
    }
  }

  smart_search_template(item) {
    if (!item.id) { return item.text; }
    return $((item.thumbnail_url ? "<img class='rounded' width='40' src='"
      + item.thumbnail_url + "'/> " : '')
      + "<span>" + item.name + "</span>");
  }

  hotkeyMount() {
    let controller = this
    hotkeys('/', function() { $(controller.element).select2('open') })
  }

  hotkeyUnMount() { hotkeys.unbind('/') }

}
