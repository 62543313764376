import { Controller } from "stimulus"
import flatpickr from "flatpickr";

export default class extends Controller {

  static targets = ['start', 'end']

  connect() {
    this.startTarget.addEventListener('change', (event) => {
      this.updateEnd(this.startTarget, this.endTarget)
    })
  }

  updateEnd(startnode, endnode) {
    // TODO: this is not working - WIP!
    // let fp = flatpickr(endnode, {})
    // fp.setDate('2021-10-10')
    // fp.redraw()
  }

}
