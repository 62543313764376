import { Controller } from "stimulus"

export default class extends Controller {

  show(event) {
    event.preventDefault()
    this.element.querySelectorAll('[data-reveal-target=shower]').forEach( el => el.click() )
  }

  hide(event) {
    event.preventDefault()
    this.element.querySelectorAll('[data-reveal-target=hider]').forEach( el => el.click() )
  }

}
