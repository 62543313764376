import Conductor from 'stimulus-conductor'

export default class extends Conductor {

  static targets = [ 'master', 'slave' ]

  connect(){
    super.connect()
    this.element[this.identifier] = this // store controller into element for master/slave interactions
  }

  masterController() {
    return this.flatkidControllers.find((controller) => controller.element == this.masterTarget)
  }

  slaveController() {
    return this.flatkidControllers.find((controller) => controller.element == this.slaveTarget)
  }

  /**
   * Callback function that the children call to inform parent of date change
   * 
   * If the change occured on master, set slave to same values
   * 
   * If the change occured on slave, make sure that slave is after master
   */
  childDateChanged(child){
    if (child.element === this.slaveTarget){
      //check if slave is behind master
      if (!this.masterController()) return
      var masterDate = this.masterController().element.flatpickr.fp.selectedDates[0];
      var slaveDate = child.element.flatpickr.fp.selectedDates[0];
      if (masterDate > slaveDate){
        child.element.nextSibling.classList.remove('is-valid')
        child.element.nextSibling.classList.add('is-invalid')
      } else {
        child.element.nextSibling.classList.remove('is-invalid')
        child.element.nextSibling.classList.add('is-valid')
      }
    } else {
      //Set slave to master on master change
      if (!this.slaveController()) return
      var masterDate = child.element.flatpickr.fp.selectedDates[0]
      if (this.slaveController().element.flatpickr.fp.selectedDates.length > 0){
        // Change slave ot master only if slave was set
        this.slaveController().element.flatpickr.fp.setDate(masterDate)
      }
    }
  }
}
