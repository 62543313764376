import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ 'textarea', 'editor' ]

  connect() {
    this.startAce()
  }

  startAce() {
    // hide original textarea
    let textarea = this.textareaTarget
    textarea.classList.add('d-none')
    // launch Ace editor
    let editor = ace.edit(this.editorTarget)
    let session = editor.getSession()
    editor.setFontSize("18px")
    editor.container.style.lineHeight = 1.2
    editor.renderer.updateFontSize()
    editor.setTheme("ace/theme/solarized_light")
    editor.getSession().setMode("ace/mode/" + this.data.get('mode'))
    editor.getSession().setUseSoftTabs(true)
    editor.getSession().setTabSize(2)
    editor.getSession().setUseWrapMode(true)
    editor.getSession().setWrapLimitRange() // blackjam: free wrap?
    // editor.getSession().setWrapLimitRange(90, 95)
    editor.setPrintMarginColumn(95)
    editor.$blockScrolling = Infinity
    if (this.data.get('gutter') == 'true') {
      // shown by default
    } else { editor.renderer.setShowGutter(false) }
    // filter out unwanted HTML doctype warning
    // see: https://stackoverflow.com/questions/33232632/how-can-i-remove-the-first-doctype-tooltip-of-the-ace-editor-in-my-html-editor
    session.on("changeAnnotation", function() {
      let annotations = session.getAnnotations() || []
      let i = annotations.length
      let len = annotations.length
      while (i--) {
        if(/doctype first\. Expected || entity expected\. Got none\./.test(annotations[i].text)) {
          annotations.splice(i, 1);
        }
      }
      if(len > annotations.length) {
        session.setAnnotations(annotations);
      }
    })

    // copy text from textarea to the editor
    session.setValue(this.textareaTarget.value)

    // update textarea on each editor change
    session.on('change', function() { textarea.value = session.getValue() })
  }

}
