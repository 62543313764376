$(document).on('ready turbolinks:load', function(){
  $("a[href^='http']:not([href^='http://www.etspraha.cz']):not([href^='http://etspraha.cz']):not([href^='https://www.etspraha.cz']):not([href^='https://etspraha.cz'])").attr("target","_blank");
});

// GA + Turbolinks
document.addEventListener('turbolinks:load', function(event){
  if (typeof(ga) === 'function') {
    ga('set', 'location', event.data.url)
    ga('send', 'pageview')
  }
});

// plausible.io + Turbo
document.addEventListener('turbolinks:load', function() {
  // if (! typeof plausible === 'undefined') { plausible('pageview') }
  try { plausible('pageview') }
  catch(err) { console.log('Plausible: not available.') }
})

// BS Tools

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
})

// syntax highlighter

import hljs from 'highlight.js'

$(document).arrive('pre code', function() {
  hljs.highlightAll()
})

// responsive tables in richtext (vanilla version of plugin does not support webpack loader... so we use jQuery)

require('basictable/dist/js/jquery.basictable.js')
$(document).arrive('.richtext table', function(el){
  $(el).basictable()
})
